@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import "rsuite/dist/rsuite.css";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@import "rsuite/dist/rsuite.css";

input:focus {
  outline: none !important;
  /* border-color: aquamarine !important; */
}
.table {
}
.theading {
  /* background-color: red !important; */
  /* border-radius: 20px; */
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
.theading_last {
  /* background-color: rgb(154, 104, 104) !important; */
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.thead {
  border: none !important;
  /* border-radius: 40px !important; */
}
.trow {
  /* background-color: blue !important; */
  border-radius: 40px !important;
}
.date_picker_focous:focus {
  border: none !important;
  outline: none !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline:focus {
  border: none !important;
  outline: none !important;
  /* border-radius: 50px !important; */
  /* border-color: rebeccapurple !important; */
}
.ant-pagination-item a {
  border-radius: 6px !important;
  /* color: white !important; */
}
td,
th {
  border: none !important;
}
.mytable {
  max-height: 100vh;
  overflow-y: auto;
}

/* filter card date styling start  */
.MuiFormControl-root {
  width: 80% !important;
  /* border: 5px solid red; */
  font-size: 5px !important;
  font-family: "Open Sans";
  border: none !important;
  outline: none !important;
}
.MuiFormControl-root:focus {
  outline: none !important;
  border: none !important;
}
.MuiInputBase-input {
  padding: 6px !important;
  width: 70% !important;
  color: #6f6f6f !important;
  outline: none !important;
  border: none !important;
  font-family: "Open Sans" !important;
}
.font_styling {
  font-family: "Open Sans" !important ;
  font-weight: 600 !important;
}

.MuiOutlinedInput-input {
  /* background-color: red !important; */
}
/* filter card date styling end  */

/* Table component start here -----------  */
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  /* max-height: 20%; */
  /* overflow-y: scroll; */
}

td {
  /* background-color: red; */
  /* border-radius: 10px; */
}
td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  /* padding: 8px; */
}

/* .theading {
}
/* Table component end here ---------  */

/* pagination start from here -------- */
.ant-pagination-item-1 {
  /* background-color: #494847 !important; */
  color: rgb(0, 0, 0);
  /* border-radius: none !important; */
}
input[type="checkbox"]:checked {
  /* accent-color: #e98700; */
  color: white !important;
  cursor: pointer;
}
.a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: white !important;
}

.rs-picker-toolbar-right > button,
[type="reset"],
[type="submit"] {
  background-color: none !important;
  /* border: 1px solid gray !important; */
  background-color: #f68f00 !important;
}

/* date range picker css start from here ---  */
.rs-picker-daterange-menu {
  z-index: 10000000000 !important;
  /*added a random number*/
}

.rs-picker-toggle-wrapper {
  width: 100%;
}

.range_picker .rs-picker-toggle-placeholder {
  float: left;
  font-family: "Gm";
  font-size: 14px;
  line-height: 22px;
  color: #221e1f !important;
}

.rs-picker-toggle-placeholder {
  float: left;
  font-family: "Gm";
  font-size: 14px;
  line-height: 22px;
  color: #221e1f !important;
}

/* .rs-icon {
  display: none;
} */

.rs-btn-link {
  background: #f4f4f4 !important;
  color: #221e1f !important;
  margin-left: 2px !important;
  margin-right: 2px !important;
  text-decoration: none !important;
}

.btn_join_meeting:active,
.btn_join_meeting:focus {
  color: #ffffff !important;
}

a:active,
a:focus {
  color: #000000 !important;
}

.rs-btn-link:hover {
  background: linear-gradient(
    162.29deg,
    #ffe185 -6.85%,
    #f7ba00 100.08%
  ) !important;
}

.rs-picker-toggle {
  height: 40px;
  border-radius: 2px !important;
}

.rs-picker-toggle-active,
.rs-picker-toggle:hover {
  border: 1px solid #fcc216 !important;
  box-shadow: none !important;
}

.rs-picker-toggle-value,
.rs-picker-toggle-clean {
  color: #000000 !important;
}

.rs-picker-default .rs-btn,
.rs-picker-default .rs-picker-toggle,
.rs-picker-input .rs-btn,
.rs-picker-input .rs-picker-toggle {
  background-color: #ffffff !important;
}

.date_range-picker .rs-picker-default .rs-btn,
.rs-picker-default .rs-picker-toggle,
.rs-picker-input .rs-btn,
.rs-picker-input .rs-picker-toggle {
  background-color: #f4f4f4 !important;
}

.range_picker .rs-picker-default .rs-btn,
.rs-picker-default .rs-picker-toggle,
.rs-picker-input .rs-btn,
.rs-picker-input .rs-picker-toggle {
  background-color: #ffffff !important;
}

/* .date_range-picker .rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
  background-color: red !important;
} */

.range_picker .rs-picker-default .rs-picker-toggle,
.date_range-picker .rs-picker-default .rs-picker-toggle {
  border: none !important;
}

.date_range-picker .rs-picker-toggle-caret,
.range_picker .rs-picker-toggle-caret {
  color: #fcc216 !important;
}

.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn {
  padding-right: 11px !important;
}

.rs-picker-date.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle,
.rs-picker-daterange.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
  padding-right: 11px;
}

.follow_up_filter .rs-picker-toggle {
  border: none !important;
  height: 44px;
}

.follow_up_filter .rs-picker-toggle-active,
.range_picker .rs-picker-toggle:hover,
.date_range-picker .rs-picker-toggle:hover {
  border: 1px solid #fcc216 !important;
  box-shadow: none !important;
}

.rs-picker-toggle-textbox {
  border: 1px solid #fcc216 !important;
}

.follow_up_filter .rs-picker-default .rs-picker-toggle.rs-btn {
  padding-top: 12px !important;
  padding-bottom: 7px;
}

.follow_up_filter .rs-icon {
  width: 15px !important;
}

.follow_up_filter svg {
  top: 11px !important;
}
.custom-date-picker {
  /* border: 10px solid red; */
  width: 300px;
}
/* date range end -------  */

.ant-pagination-total-text {
  padding: 0 12px !important;
  border: 1px solid #000 !important;
}

/* paginations start -----------  */
/* .ant-pagination .ant-pagination-item a {
  border: none;
}
.ant-pagination li {
  border: none;
}
:where(.css-dev-only-do-not-override-ph9edi).ant-pagination
  .ant-pagination-item {
  border: 1px solid red;
  border-radius: 0;
}
:where(.css-dev-only-do-not-override-ph9edi).ant-pagination
  .ant-pagination-item-active {
  background: red !important;
  border: none;
  border-radius: 0;
} */

/* pagination end --------  */

/* .css-6b4liy-control {
  width: 225px !important;
}
.react-select-5-listbox {
  width: 40% !important;
} */
.css-qbdosj-Input {
  visibility: visible;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: inline-grid;
  grid-area: 1/1/2/3;
  grid-template-columns: 0 min-content;
  margin: 2px;
  padding-bottom: 2px;
  padding-top: 2px;
  color: hsl(0, 0%, 20%);
  box-sizing: border-box;
  width: 200px;
}

.navlinks:active {
  color: none !important;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

:root {
  --primary_bg: #f5f5f5;
  --crm_orange: #f7b314;
  --placeholder_text: #646464;
  --nonLinearGradient_btn: #f7b314;
  --grey_bg: #f4f4f4;
  --light_btn: #ffecbe;
  --border_clr: #e0e0e0;
}
/* log in page styles  */
.login_left {
  height: 100vh;
  width: 50%;
  background-image: url(../asset/images/img_1.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.login_right {
  height: 100vh;
  background: #ffffff;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.login_label {
  color: var(--placeholder_text);
  font-size: 16px;
  margin: 11px 0;
}

.input_details {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  font-family: "Inter";
  font-style: normal;
}
.error_mess {
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter";
  font-style: normal;
  color: #ff0000;
}
.login_form input {
  height: 50px;
  width: 100%;
  outline: none;
  font-family: "Inter";
  font-style: normal;
  border-radius: 5px;
  border-color: #e8e8e8;
  border-style: solid;
  padding-left: 20px;
}

.login_form input[type="text"] {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  font-family: "Inter";
  font-style: normal;
}

.login_btn {
  height: 50px;
  width: 347px;
  text-align: center;
  background: linear-gradient(to right, #ffdb6c, var(--nonLinearGradient_btn));
  border-radius: 5px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  font-family: "Inter";
  font-style: normal;
  margin-top: 31px;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login_form h2 {
  margin-top: 64px;
  margin-bottom: 39px;
  color: #1a202c;
  font-weight: 700;
  size: 25px;
  line-height: 42px;
  line-height: 116%;
}
.loading {
  height: 20px;
  width: 20px;
}
.login_form .login_logo {
  width: 146px;
  height: 90px;
}
/* log in page styles end  */
.side_bar {
  height: 100vh;
  width: 220px;
  position: fixed;
  top: 0;
  left: 0;
}
.side_bar ul {
  width: 100%;
  padding: 12px;
}
.side_bar ul li {
  padding: 12px;
  list-style-type: none;
  margin-top: 30px;
  border-radius: 5px;
  width: 100%;
  background: var(--primary_bg);
}
.side_bar ul li:hover {
  background: var(--crm_orange);
}
.side_bar ul li a {
  text-decoration: none;
  /* color: #000; */
}

.side_bar svg path {
  fill: black;
}
.svg_nav_logo {
  margin-right: 15px;
}

.main {
  margin: 12px 12px 12px 0px;
  padding: 12px;
  background: var(--primary_bg);
  width: 100%;
  margin-left: 220px;
}
.CS_head_txt {
  font-size: 20px;
  line-height: 23px;
  color: #000000;
  font-weight: 500;
}
.total_count_txt {
  font-size: 14px;
  line-height: 16px;
  color: #fcc216;
}
.search_div {
  background: #fff;
  border-radius: 2px;
  padding: 0px 10px;
  width: 45%;
  display: flex;
  align-items: center;
}
.top_menu_search {
  border: none;
  width: 90%;
  font-size: 13px;
  line-height: 14px;
  color: #000000;
}

.top_menu_search::placeholder {
  color: #000000;
  font-size: 13px;
}

.top_menu_search:focus {
  outline: none;
  box-shadow: none;
}

.add_btn,
.search_button,
.add_partner_lead {
  padding: 10px 15px;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 2px;
  background: white;
  font-size: 12px;
  line-height: 14px;
  gap: 10px;
  height: 36px;
}
.search_button {
  background: #fff2c8;
  color: #000000;
}
.add_btn:focus,
.add_partner_lead:focus,
.search_button:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.item_border {
  border-bottom: 1px solid #e9e9e9 !important;
}

input[type="checkbox"] {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  background: #ffffff;
  height: 17px;
  width: 19px;
  border: 0.5px solid #e98700;
  box-sizing: border-box;
  /* border-radius: 200px; */
  overflow: hidden;
}
input[type="checkbox"]:nth-child(1) {
  /* background-color: red !important; */
}
.largerCheckbox {
  /* background-color: pink !important; */
  /* border-radius: 200px; */
}

input[type="checkbox"]:after {
  content: " ";
  background-color: #e98700;
  color: #000000;
  height: 17px;
  width: 19px;
  border-radius: 10rem!;
}

input[type="checkbox"]:checked:after {
  content: "\2714";
  padding: 4px;
  color: white;
}

.table_div table tbody {
  background: #fff;
  border-radius: 5px;
  min-height: 80vh;
  overflow-y: auto;
}
/* thead tr,
thead tr {
  position: sticky;
  top: 0px;
  /* background: linear-gradient(95.67deg, #626262 3.24%, #0e0e0e 107.53%); */
/* }  */

th {
  border-top: none !important;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffff;
  text-align: center;
  /* position: sticky;
  top: 0px; */
  padding: 12px !important;
}

.table > :not(:first-child) {
  border-top: 0px !important;
}
td {
  vertical-align: middle;
  border: none !important;
  padding: 15px !important;
  /* width: 100%; */
  /* border-bottom: 1px solid #dee2e6 !important; */
}

tr {
  font-size: 13px;
  line-height: 15px;
  color: #000000;
  text-align: center;
  /* border-bottom: 1px solid #dee2e6 !important; */
}

table tbody tr:hover {
  color: #7a7a79;
  background: #f9f9f9 !important;
  border-radius: 10px !important;
  cursor: pointer;
}
.action_icon:hover {
  color: #f7b314;
}

.table-responsive .react-bootstrap-table {
  width: 100% !important;
}
.copy_right_txt,
.cendrol_refappadmin {
  font-size: 14px;
  line-height: 16px;
  color: #0e0e0e;
}

.cendrol_refappadmin {
  color: #fcc314 !important;
}

/* pagination styles start  */
/* .ant-pagination,
.ant-pagination-item a,
.ant-pagination-total-text {
  font-size: 14px !important;
  color: #0e0e0e !important;
} */

/* .ant-pagination-item a,
.ant-pagination-total-text {
  line-height: 30px;
  color: white !important;
  border: 1px solid gray !important;
  color: #000000 !important  ;
  border-radius: 2px;
} */

.ant-pagination-item:hover {
  border: none !important;
  outline: none !important;
  border-color: none !important;
  background-color: #494847 !important;
}

.ant-pagination-item:active {
  color: rgb(255, 255, 255) !important;
  background: #000;
}

.ant-pagination-item-active {
  background: #494847 !important;
  border: none !important;
  color: white !important;
  background: #000 !important;
}

/* #fcc216 */

/* .ant-pagination-options-quick-jumper input:hover,
.ant-pagination-options-quick-jumper input:focus {
  border-color: #fcc216 !important;
  box-shadow: none !important;
}

.ant-picker-dropdown {
  z-index: 99999 !important;
}

.ant-picker-focused {
  border-color: #fcc216 !important;
  box-shadow: none !important;
}
:where(.css-dev-only-do-not-override-yp8pcc).ant-pagination
  .ant-pagination-item {
  border-radius: 2px;
  outline: 0;
  cursor: pointer;
  user-select: none;
} */

/* pagination styles end  */
.close {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background: #e6e6e6;
}
.close_icon {
  outline: none;
  border: none;
  background: white;
}
.add_hot_lead_label {
  font-weight: 600;
  font-size: 20px;
}

.btn_resetall,
.btn_apply_filter {
  background: #f2f2f2;
  width: 100%;
  border-radius: 3px;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  border: none;
  outline: none;
  padding: 14px;
}

.btn_apply_filter {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  display: flex;
  justify-content: center;
  gap: 15px;
}

.filter_input_head_txt,
.lable_dropdown_options {
  font-size: 13px;
  line-height: 15px;
  text-align: center;
}
.field_input_txt {
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  color: #0e0e0e;
  padding: 5px 14px 5px 14px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 3px;
  outline: none;
  height: 40px;
}
.field_input_txt::placeholder {
  color: #000;
}
.modal-content {
  border-radius: 4px;
}
.field_input_txt_add_lead {
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  color: #6c757d;
  padding: 6px 12px 6px 6px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 3px;
  outline: none;
  height: 43px;
}

.field_input_txt_add_lead::placeholder {
  color: #000000;
}

.field_input_txt_add_lead:focus {
  outline: none;
  border: 1px solid #fcc216;
}

.field_input_txt_add_lead option {
  color: #000;
}

.field_input_txt:focus,
.field_input_txt:hover {
  outline: none;
  border: 1px solid #fcc216;
}
.form-select:focus {
  box-shadow: none;
}

.select-form {
  font-size: 13px;
  line-height: 15px;
  color: #221e1f;
  height: 100%;
}
.bluck_input,
.bluck_select {
  padding: 10px;
  border: 1px solid #ced4da;
  font-size: 14px;
  border-radius: 3px;
}
.bluck_select:hover,
.bluck_select:focus {
  box-shadow: none;
  border-color: #fcc216;
}
.bluck_input:hover {
  border-color: #fcc216;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.to_txt {
  font-size: 13px;
  line-height: 15px;
  color: #969696;
}
.back_btn {
  cursor: pointer;
  font-weight: 500;
  width: 45px;
}
.text_prime {
  color: #969696;
  cursor: pointer;
}
.text_non_prime {
  font-weight: 500;
  font-size: 15px;
  margin-left: 10px;
}

.popup_btn_reupload {
  color: #000000;
  background: linear-gradient(93.39deg, #ffe185 -2.76%, #f7ba00 111.1%);
  border-radius: 2px;
  padding: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #000000;
  width: 100%;
  border: none;
}

.popup_btn_close {
  color: #221e1f;
  background: #f4f4f4;
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  border: none;
  font-size: 13px;
  line-height: 15px;
}
.popup_error_csv_txt {
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #0e0e0e;
  overflow-wrap: break-word;
}
.form_head_txt {
  font-size: 18px;
  line-height: 21px;
  color: #0e0e0e;
}
.filter_input_head_txt,
.lable_dropdown_options {
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #0e0e0e;
}
.error {
  color: #dc3545;
  font-size: 12px;
}
.logout {
  padding: 5px 15px;
  border: none;
  outline: none;
  border-radius: 2px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  background: black;
}
.popup_unlinkbankdetails_icon {
  color: #ff3535 !important;
  font-size: 50px !important;
}
.popup_icon_center,
.popup_text_center {
  flex-direction: row;
  text-align: center;
}
.popup_btn_unlinkaccount,
.popup_btn_linkaccount {
  color: #ffffff;
  background: linear-gradient(180deg, #ff3535 -10.87%, #af1818 111.12%);
  border-radius: 2px;
  width: 100%;
  padding: 10px;
  border: none;
  font-size: 13px;
  line-height: 15px;
}
.text_one {
  color: #717171;
  font-weight: 500;
  font-size: 14px;
}
.text_two {
  font-weight: 500;
  font-size: 15px;
}
.for_select {
  width: 12rem;
  padding: 5px 10px;
  border-radius: 4px;

  border: 2px solid #c0c0c0;
}
.for_select:focus,
.for_select:hover {
  border-color: var(--crm_orange);
}
.wapper {
  padding: 10px;
  background: white;
  margin-top: 30px;
}
.login_containers {
  background-color: #333333;
  height: 100vh;
  width: 100vw;
  background-image: url("../asset//images/loginBg.png");
  background-repeat: no-repeat;
  /* background-size: 700px 900px; */
  background-size: 750px 650px;
  background-position: 0 105px;
}
.login_box {
  background: linear-gradient(116.31deg, #fcbd65 -25.27%, #e98700 126.96%);
  border-radius: 20px;
}
.login_box_admin {
  background-color: #fff;
  border-radius: 50px 50px 20px 20px;
  padding: 0px 20px;
}
.admin_login_text {
  padding-top: 10px;
  padding-bottom: 30px;
}
.input_box input {
  background-color: #fcfcfc;
  padding: 10px;
  width: 22rem;
  border-radius: 4px;
  font-size: 17px;
}
.input_box2 input {
  background-color: #fcfcfc;
  padding: 10px;
  width: 19rem;
  border-width: 2px;
  border: none;
}
.login_btn {
  height: 50px;
  width: 351px;
  text-align: center;
  background-color: #e98700;
  color: white;
  border-radius: 11px;
  border: none;
  font-size: 24px;
  font-weight: 700;
  font-family: "Inter";
  font-style: normal;
  margin-top: 31px;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
}
.error_mess {
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter";
  font-style: normal;
  color: #ff0000;
}
